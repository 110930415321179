
import Me from "~/graphql/Authentication/Me.gql";

import {
  LIST_PERMISSION_PRIVATE,
  LIST_PERMISSION_USER_DEFINED,
  LIST_PERMISSION_ORGANIZATION,
  USER_PERMISSION_NONE,
  USER_PERMISSION_READ,
  USER_PERMISSION_WRITE,
  USER_PERMISSION_ADMIN
} from "./constants.js"

export default {
  apollo: {
    me: {
      query: Me,
    },
  },

  props: {
    users: {
      type: Array,
      required: true
    },

    listPermission: {
      type: String,
      required: true
    },

    enforcePermissionTemplates: {
      type: Boolean,
      default: false
    }
  },

  LIST_PERMISSION_PRIVATE: LIST_PERMISSION_PRIVATE,
  LIST_PERMISSION_USER_DEFINED: LIST_PERMISSION_USER_DEFINED,
  LIST_PERMISSION_ORGANIZATION: LIST_PERMISSION_ORGANIZATION,

  USER_PERMISSION_NONE: USER_PERMISSION_NONE,
  USER_PERMISSION_READ: USER_PERMISSION_READ,
  USER_PERMISSION_WRITE: USER_PERMISSION_WRITE,
  USER_PERMISSION_ADMIN: USER_PERMISSION_ADMIN,

  data() {
    return {
      userListVisible: false,
      userListCollapsed: true,
      userCheckVisible: true,
      userFilterValue: "",
      userCurrentNotAdmin: false,
      userCurrentNotMember: false,
      usersIntermediate: this.users
    };
  },

  computed: {
    usersFiltered() {
      if (this.userFilterValue.length > 0) {
        return this.usersIntermediate.filter(u => u.name.toLowerCase().lastIndexOf(this.userFilterValue) !== -1);
      }

      return this.users;
    },

    usersWithAdminRights() {
      return this.usersIntermediate.filter(u => u.permission === this.$options.USER_PERMISSION_ADMIN);
    },

    /*
      READ
    */
    checkedReadAll() {
      return this.usersIntermediate.length === this.usersIntermediate.filter(u => u.permission === this.$options.USER_PERMISSION_READ || u.permission === this.$options.USER_PERMISSION_ADMIN).length;
    },

    checkedReadAllIndeterminate() {
      const l = this.usersIntermediate.filter(u => u.permission === this.$options.USER_PERMISSION_READ || u.permission === this.$options.USER_PERMISSION_ADMIN).length;

      return l > 0 && l !== this.usersIntermediate.length;
    },

    /*
      ADMIN
    */
    checkedAdminAll() {
      return this.usersIntermediate.length === this.usersIntermediate.filter(u => u.permission === this.$options.USER_PERMISSION_ADMIN).length;
    },

    checkedAdminAllIndeterminate() {
      const l = this.usersIntermediate.filter(u => u.permission !== this.$options.USER_PERMISSION_ADMIN).length;

      return l > 0 && l !== this.usersIntermediate.length;
    }
  },

  watch: {
    usersIntermediate() {
      this.$emit('users-update', this.usersIntermediate);
    },

    listPermission(value) {
      switch (value) {
        case this.$options.LIST_PERMISSION_PRIVATE: {
          this.userListVisible = false;
          this.userListCollapsed = true;
          this.userCheckVisible = true;
          this.userCurrentNotAdmin = false;
          this.userCurrentNotMember = false;

          if (this.enforcePermissionTemplates) {
            this.usersIntermediate = this.usersIntermediate.map(u => {
              const user = structuredClone(u);

              if (user.id === this.me.id) {
                user.permission = this.$options.USER_PERMISSION_ADMIN;
              } else {
                user.permission = this.$options.USER_PERMISSION_NONE;
              }

              return user;
            });
          }
          this.$emit('list-permission-update', this.$options.LIST_PERMISSION_PRIVATE);
          this.$emit('users-update', this.usersIntermediate);
          break;
        }

        case this.$options.LIST_PERMISSION_USER_DEFINED: {
          this.userListVisible = true;
          this.userListCollapsed = false;
          this.userCheckVisible = true;

          if (this.enforcePermissionTemplates) {
            this.usersIntermediate = this.usersIntermediate.map(u => {
              if (u.id === this.me.id) {
                u.permission = this.$options.USER_PERMISSION_ADMIN;
              } else {
                u.permission = this.$options.USER_PERMISSION_NONE;
              }

              return u;
            });

          }

          this.checkCurrentUserPermission();

          this.$emit('list-permission-update', this.$options.LIST_PERMISSION_USER_DEFINED);
          this.$emit('users-update', this.usersIntermediate);
          break;
        }

        case this.$options.LIST_PERMISSION_ORGANIZATION: {
          this.userListVisible = true;
          this.userListCollapsed = true;
          this.userCheckVisible = false;
          this.userCurrentNotAdmin = false;
          this.userCurrentNotMember = false;

          if (this.enforcePermissionTemplates) {
            this.usersIntermediate = this.usersIntermediate.map(u => {
              if (u.id === this.me.id) {
                u.permission = this.$options.USER_PERMISSION_ADMIN;
              } else {
                u.permission = this.$options.USER_PERMISSION_READ;
              }

              return u;
            });
          }

          this.$emit('list-permission-update', this.$options.LIST_PERMISSION_ORGANIZATION);
          this.$emit('users-update', this.usersIntermediate);
          break;
        }
      }
    }
  },

  methods: {
    editUserList() {
      this.userListCollapsed = false;
    },

    /*
      READ
    */
    setCheckedRead(user) {
      this.usersIntermediate = this.usersIntermediate.map(u => {
        if (u.id === user.id) {
          switch(user.permission) {
            case this.$options.USER_PERMISSION_ADMIN:
              u.permission = this.$options.USER_PERMISSION_NONE;
              break;

            case this.$options.USER_PERMISSION_READ:
              u.permission = this.$options.USER_PERMISSION_NONE;
              break;

            default:
              u.permission = this.$options.USER_PERMISSION_READ;
              break;
          }
        }

        return u;
      });

      this.ensureOneAdmin();
      this.checkCurrentUserPermission();
    },

    setCheckedReadAll(value) {
      this.usersIntermediate = this.usersIntermediate.map(u => {
        u.permission = value ? this.$options.USER_PERMISSION_READ : this.$options.USER_PERMISSION_NONE;

        return u;
      });

      this.ensureOneAdmin();
      this.checkCurrentUserPermission();
    },

    getCheckedRead(user) {
      return user.permission === this.$options.USER_PERMISSION_READ;
    },

    /*
      ADMIN
    */
    setCheckedAdmin(user) {
      this.usersIntermediate = this.usersIntermediate.map(u => {
        if (u.id === user.id) {
          switch(user.permission) {
            case this.$options.USER_PERMISSION_ADMIN:
              u.permission = this.$options.USER_PERMISSION_READ;
              break;

            case this.$options.USER_PERMISSION_READ:
              u.permission = this.$options.USER_PERMISSION_ADMIN;
              break;

            default:
              u.permission = this.$options.USER_PERMISSION_ADMIN;
              break;
          }
        }

        return u;
      });

      this.ensureOneAdmin();
      this.checkCurrentUserPermission();
    },

    setCheckedAdminAll(value) {
      this.usersIntermediate = this.usersIntermediate.map(u => {
        if (u.permission === this.$options.USER_PERMISSION_ADMIN) {
          u.permission = value ? this.$options.USER_PERMISSION_ADMIN : this.$options.USER_PERMISSION_READ;
        } else {
         u.permission = value ? this.$options.USER_PERMISSION_ADMIN : this.$options.USER_PERMISSION_NONE;
        }

        return u;
      });

      this.ensureOneAdmin();
      this.checkCurrentUserPermission();
    },

    getCheckedAdmin(user) {
      return user.permission === this.$options.USER_PERMISSION_ADMIN;
    },

    getIsUserOnlyAdmin(user) {
      const usersWithAdminPermissions = this.usersIntermediate.filter(u => u.permission === this.$options.USER_PERMISSION_ADMIN);

      return usersWithAdminPermissions.length === 1 && usersWithAdminPermissions[0].id === user.id;
    },

    ensureOneAdmin() {
      const usersWithAdminPermissions = this.usersIntermediate.filter(u => u.permission === this.$options.USER_PERMISSION_ADMIN);

      if (usersWithAdminPermissions.length === 0) {
        this.usersIntermediate = this.usersIntermediate.map(u => {
          if (u.id === this.me.id) {
            u.permission = this.$options.USER_PERMISSION_ADMIN;
          }

          return u;
        });
      }
    },

    checkCurrentUserPermission() {
      this.userCurrentNotAdmin = this.usersIntermediate.filter(u => u.id === this.me.id && u.permission === this.$options.USER_PERMISSION_ADMIN).length === 0;
      this.userCurrentNotMember = this.userCurrentNotAdmin && this.usersIntermediate.filter(u => u.id === this.me.id && u.permission === this.$options.USER_PERMISSION_READ).length === 0;
    },
  }
};
